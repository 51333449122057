// CallToAction.js (React + JSX)

import React from "react";
import "./CallToAction.css";

const CallToAction = () => {
  return (
    <section className="cta">
      <div className="cta__container">
        <div className="cta__content">
          <h2 className="cta__heading">
            Create your account today and get started for free!
          </h2>
          <div className="cta__buttons">
            <button className="cta__button cta__button--primary">
              Get started
            </button>
            <button className="cta__button cta__button--secondary">
              Talk to sales
            </button>
          </div>
        </div>
        <div className="cta__image">
          <img
            src="path-to-your-image.png" // Replace with the actual path to the image
            alt="Customer support"
            className="cta__image-content"
          />
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
