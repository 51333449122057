// Solutions.js (React + JSX)

import React from "react";
import "./Solutions.css";

const Solutions = () => {
  return (
    <section className="solutions">
      <h2 className="solutions__title">
        Empowering Businesses with Tailored Digital Solutions
      </h2>
      <p className="solutions__subtitle">
        Michel India offers a broad spectrum of software solutions designed to
        meet the unique needs of businesses in various industries. Our
        comprehensive suite of services includes
      </p>
      <div className="solutions__container">
        {/* Solution 1: Custom Software Development */}
        <div className="solution-card">
          <img
            src="path-to-your-custom-software-image.png" // Replace with actual image path
            alt="Custom Software Development"
            className="solution-card__image"
          />
          <h3 className="solution-card__title">Custom Software Development</h3>
          <p className="solution-card__description">
            "Building bespoke software tailored to your specific business
            requirements."
          </p>
        </div>

        {/* Solution 2: Mobile App Development */}
        <div className="solution-card">
          <img
            src="path-to-your-mobile-app-image.png" // Replace with actual image path
            alt="Mobile App Development"
            className="solution-card__image"
          />
          <h3 className="solution-card__title">Mobile App Development</h3>
          <p className="solution-card__description">
            Creating intuitive and powerful mobile applications that engage
            users and enhance your brand.
          </p>
        </div>

        {/* Solution 3: Cloud-Based Solutions */}
        <div className="solution-card">
          <img
            src="path-to-your-cloud-based-image.png" // Replace with actual image path
            alt="Cloud-Based Solutions"
            className="solution-card__image"
          />
          <h3 className="solution-card__title">Cloud-Based Solutions</h3>
          <p className="solution-card__description">
            Leveraging the power of the cloud to provide scalable, secure, and
            efficient solutions.
          </p>
        </div>

        {/* Solution 4: AI & Machine Learning Integration */}
        <div className="solution-card">
          <img
            src="path-to-your-ai-ml-image.png" // Replace with actual image path
            alt="AI & Machine Learning Integration"
            className="solution-card__image"
          />
          <h3 className="solution-card__title">
            AI & Machine Learning Integration
          </h3>
          <p className="solution-card__description">
            Integrating intelligent solutions that drive automation, insights,
            and competitive advantage.
          </p>
        </div>

        {/* Solution 5: UI/UX Design and Development */}
        <div className="solution-card">
          <img
            src="path-to-your-ui-ux-image.png" // Replace with actual image path
            alt="UI/UX Design and Development"
            className="solution-card__image"
          />
          <h3 className="solution-card__title">UI/UX Design and Development</h3>
          <p className="solution-card__description">
            Crafting user-centric designs that offer seamless and engaging
            experiences.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
