// AboutUs.js (React + JSX)

import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us__content">
        <div className="about-us__image-container">
          <img
            src="path-to-your-image.png" // Use correct path for your image
            alt="Developer working"
            className="about-us__image"
          />
        </div>
        <div className="about-us__text">
          <h2 className="about-us__title">
            Pioneers in Software Development and Digital Transformation
          </h2>
          <p className="about-us__description">
            At Michel India, we are passionate about technology and its
            potential to change the world. Founded with a vision to deliver
            excellence in software development, we have grown into a
            multifaceted company specializing in cutting-edge solutions for
            diverse industries. Our team of dedicated professionals combines
            creativity, technical expertise, and strategic thinking to build
            solutions that empower our clients to achieve their goals.
          </p>
          <div className="about-us__mission-vision">
            <div className="about-us__mission">
              <h3 className="about-us__mission-title">Our Mission</h3>
              <p className="about-us__mission-description">
                To innovate and deliver robust, scalable, and impactful digital
                solutions that drive success for our clients worldwide.
              </p>
            </div>
            <div className="about-us__vision">
              <h3 className="about-us__vision-title">Our Vision</h3>
              <p className="about-us__vision-description">
                To be a global leader in software development and digital
                transformation, recognized for our quality, integrity, and
                commitment to excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
