// OurProducts.js (React + JSX)

import React from "react";
import "./OurProducts.css";

const OurProducts = () => {
  return (
    <section className="products">
      <h2 className="products__title">Our Products</h2>
      <div className="products__container">
        {/* Codexodus Product Card */}
        <div className="product-card product-card--highlight">
          <img
            src="path-to-your-codexodus-logo.png" // Replace with the actual path
            alt="Codexodus Logo"
            className="product-card__image"
          />
          <h3 className="product-card__subtitle">
            THE ULTIMATE LEARNING PLATFORM FOR FUTURE CODERS
          </h3>
          <h1 className="product-card__name">Codexodus</h1>
          <p className="product-card__description">
            Codexodus is an innovative programming education tool designed to
            nurture the next generation of developers. With a focus on
            interactive learning, gamification, and community engagement,
            Codexodus provides a comprehensive platform that makes coding fun,
            accessible, and effective.
          </p>
          <button className="product-card__button">Learn more</button>
        </div>

        {/* Codexodus Secondary View Card */}
        <div className="product-card">
          <img
            src="path-to-your-laptop-image.png" // Replace with the actual path for Codexodus second image
            alt="Codexodus on Laptop"
            className="product-card__image-overlay"
          />
          <h2 className="product-card__overlay-title">Codexodus</h2>
        </div>

        {/* Urban Service App Product Card */}
        <div className="product-card">
          <img
            src="path-to-your-urban-app-image.png" // Replace with the actual path for Urban Service App
            alt="Urban Service App"
            className="product-card__image-overlay"
          />
          <h2 className="product-card__overlay-title">Urban Service App</h2>
        </div>
      </div>
    </section>
  );
};

export default OurProducts;
